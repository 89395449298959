import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { setStore, t } from "../../utils/utils";
import { toast } from 'react-toastify';
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import Config from '../../config';
import _ from "lodash";



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontStyle: "normal",
    padding: "20px 122px 0",
    justifyContent: "space-around",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    flexDirection: "column",
    lineHeight: "20px",
    color: "#FFF",
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      padding: 0,
      flexDirection:"column",
    },
  },
  title: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
  },
  img: {
    maxWidth: "100%",
  },
  
}));

const About = (props) => {
  const classes = useStyles();
  const { modal } = useSelector(getData);
  const navigate = useHistory();



  return (
    <section id="about" className="about">
        <h2>About Hvaldimir</h2>
        <p>
          Hvaldimir is a beluga whale, trained by the Russian Navy, who became a global sensation. Now, he's making waves in the crypto space with his meme token, creating a fun and friendly community for all crypto enthusiasts.
        </p>
    </section>
  )
}

export default withRouter(About);
