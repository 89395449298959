import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { setStore, t } from "../../utils/utils";
import { toast } from 'react-toastify';
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import Config from '../../config';
import _ from "lodash";
import OpenAI from 'openai';


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "calc(100vh - 104px);",
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
  },
  leftblock: {
    textAlign: "center",
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  img: {
    height: "100%",
    margin: "auto",
    padding: "40px",
  },
  btn: {
    backgroundColor: "#00E5FF",
    color: "#111",
    padding: "15px 30px",
    textDecoration: "none",
    borderRadius: "5px",
    margin: "10px auto",
    display: "block",
    width: "240px",
  },
    h1: {
      color:"#00E5FF",
    },
}));

const Home = (props) => {
  const classes = useStyles();
  const { modal } = useSelector(getData);
  const navigate = useHistory();
  const [messages, setMessages] = useState([{role:'user',content:'Who are you?'},{role:'system',content:"I'm Donaldo Tromp, here to eradicate Fake news from the internet."}]);
  const [text, setText] = useState("");

  
  const client = new OpenAI({
    apiKey: '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',
    baseURL: 'https://api.groq.com/openai/v1',
    dangerouslyAllowBrowser: true
  });
  
  const tredots = <><span className="loader__dot">.</span><span className="loader__dot">.</span><span className="loader__dot">.</span></>

  const renderMSs = (messages) => {
    return (
      messages.map((item)=>{
        if (item.role == 'user') {
          return (
            <p><span className={classes.h1}>You:</span>{item.content}</p>
          )
        } else {
          return (
            <p><span className={classes.h1}>Donaldo:</span>{item.content}</p>
          )
        }
      })
    )
  }
  


  const handleChange = (e) => {
    setText(e.target.value);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const usermsg = text;

    setText("");
    const currentmsg = [...messages, {role:'user',content:usermsg}];
    setMessages([...currentmsg, {role:'system',content:tredots}]);
    const firstmessage = [{role: "system", content: "You are Donaldo, you have speech as Donald Trump. Your main goal is you needs to talk like Donald Trump. Saying fake news. Tremendous, the best etc etc."}]
    var tempmessages = firstmessage.concat(currentmsg)
    console.log(tempmessages)
    const chat_response = await client.chat.completions.create({
      messages: tempmessages,
      model: "gemma2-9b-it",
    })
    console.log("Chat response:", chat_response)
    setMessages([...currentmsg, {role:'system',content: chat_response.choices[0]?.message?.content}])

  }

  return (
    <section id="chat" className={classes.root}>
        <div className={classes.leftblock}>
          <img src="donaldo.png" className={classes.img} />
          <div className={classes.title}>
              <h1 className={classes.h1}>Meet Donaldo Tromp</h1>
              <p>An autonomous AI agent tasked with identifying and eradicating fake news. This very ambitious Gentleman will not stop until he rules over all</p>
              <a href="#chat" className={classes.btn}>Chat with Donaldo</a>
          </div>
        </div>
        <div className="terminal">
            <div className="terminal-header">
                <div className="buttons">
                    <span className="red"></span>
                    <span className="yellow"></span>
                    <span className="green"></span>
                </div>
                <span className="terminal-title">chat-with-Donaldo@ BUY $DONALD:~</span>
            </div>
            <div className="terminal-body">
                <div id="chat-output">
                    { renderMSs(messages) }
                </div>
                <form onSubmit={handleSubmit}>
                  <input type="text" id="chat-input" placeholder="Type your message..."  value={text} onChange={handleChange} />
                </form>
            </div>
        </div>
    </section>
    
  )
}

export default withRouter(Home);
