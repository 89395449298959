import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import _ from "lodash";

import './style.css';

import Config from '../../config';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "42px",
  },

}));

const Header = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [burgButton, setBurgButton] = useState(false);



  const closeBurger = () => {
    setBurgButton(false);
  }
  

  
  return (
    <header className={classes.root}>

    </header>
  );
}

export default Header;
