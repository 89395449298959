import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { setStore, t } from "../../utils/utils";
import { toast } from 'react-toastify';
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import Config from '../../config';
import _ from "lodash";



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontStyle: "normal",
    padding: "20px 122px 0",
    justifyContent: "space-around",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    flexDirection: "column",
    lineHeight: "20px",
    color: "#FFF",
    "@media (max-width: 767.98px)": {
      width: "auto",
      height: "auto",
      padding: 0,
      flexDirection:"column",
    },
  },
  title: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
  },
  img: {
    width: "50%",
    margin: "auto",
  },
  
}));

const Chat = (props) => {
  const classes = useStyles();
  const { modal } = useSelector(getData);
  const navigate = useHistory();



  return (
    <section id="chat" className="terminal-section">
        <img src="logo.png" className={classes.img} />
        <div className="terminal">
            <div className="terminal-header">
                <div className="buttons">
                    <span className="red"></span>
                    <span className="yellow"></span>
                    <span className="green"></span>
                </div>
                <span className="terminal-title">chat-with-hvaldimir@blockchain:~</span>
            </div>
            <div className="terminal-body">
                <div id="chat-output">
                    <p><span className="user">You:</span> Who are you?</p>
                    <p><span className="hvaldimir">Hvaldimir:</span> 
                      I'm Hvaldimir, the beluga whale! You may know me from the news.
                    </p>
                </div>
                <input type="text" id="chat-input" placeholder="Type your message..." />
            </div>
        </div>
    </section>
  )
}

export default withRouter(Chat);
